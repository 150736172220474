/*     	Customable Layouts Colors     */

%nav-background-color-style {
	.nav-search{
		.input-group {
			border: 0;
			background: rgba(0, 0, 0, 0.14)!important;
			box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.07);
			transition: all .4s;
			.form-control {
				color: $white-color !important;
				&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					opacity: 1;
				}
				&::-moz-placeholder { /* Firefox 19+ */
					opacity: 1;
				}
				&:-ms-input-placeholder { /* IE 10+ */
					opacity: 1;
				}
				&:-moz-placeholder { /* Firefox 18- */
					opacity: 1;
				}
			}
			.search-icon {
				color: $white-color !important;
			}
			&.focus {
				background: #fff !important;
				.form-control {
					color: inherit !important;
					&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
						color: #bfbfbf !important;
					}
					&::-moz-placeholder { /* Firefox 19+ */
						color: #bfbfbf !important;
					}
					&:-ms-input-placeholder { /* IE 10+ */
						color: #bfbfbf !important;
					}
					&:-moz-placeholder { /* Firefox 18- */
						color: #bfbfbf !important;
					}
				}
				.search-icon {
					color: #bfbfbf !important;
				}
			}
		}
	}
	.navbar-nav .nav-item {
		.nav-link {
			color: $white-color !important;
			&:hover, &:focus {
				background: rgba(31, 30, 30, 0.12) !important;
			}
		}
		&.active {
			.nav-link {
				background: rgba(31, 30, 30, 0.12) !important;
			}
		}
	}
}

.main-header {
	.navbar-header{
		&[data-background-color]{
			@extend %nav-background-color-style;
		}
		&[data-background-color="dark"], &[data-background-color="dark2"] {
			.nav-search {
				.input-group {
					background: rgba(185, 185, 185, 0.18) !important;
				}
			}
			.navbar-nav .nav-item {
				.nav-link {
					&:hover, &:focus {
						background: rgba(185, 185, 185, 0.18) !important;
					}
				}
				&.active {
					.nav-link {
						background: rgba(185, 185, 185, 0.18) !important;
					}
				}
			}
		}
		&[data-background-color="white"], .navbar-header-transparent  {
			.nav-search {
				.input-group {
					background: #eee;
					.form-control {
						color: #8d9498 !important;
					}
					.search-icon {
						color: #8d9498 !important;
					}
				}
			}
			.navbar-nav .nav-item {
				.nav-link {
					color: #8d9498 !important;
					&:hover, &:focus {
						background: #eee !important;
					}
				}
				&.active {
					.nav-link {
						background: #eee !important;
					}
				}
			}
		}
	}
}

.btn-toggle {
	color: #fff !important;
}
.logo-header{
	&[data-background-color]{
		.navbar-toggler {
			.navbar-toggler-icon {
				color: $white-color !important;
			}
		}
		.more {
			color: $white-color !important;
		}
	}
	&[data-background-color="white"] {
		.navbar-toggler .navbar-toggler-icon, .more, .btn-toggle {
			color: #8d9498 !important;
		}
	}
}

@mixin main-header-color-style($color) {
	background: $color !important;
}

@mixin logo-header-color-style($color) {
	background: $color !important;
}

@mixin navbar-header-color-style($color) {
	background: $color !important;
}

@mixin sidebar-color-style($color) {
	background: $color !important;
	.user {
		border-color: rgba(181, 181, 181, 0.1) !important;
		.info a > span {
			color: #b9babf;
			.user-level {
				color: #8d9498;
			}
		}
	}
	.nav {
		> .nav-item {
			&.active {
				> a {
					p {
						color: #b9babf !important;
					}
				}
			}
			a {
				color: #b9babf !important;
				&:hover, &:focus, &[data-toggle=collapse][aria-expanded=true] {
					p {
						color: #b9babf !important;
					}
				}
			}
		}
	}
	&.sidebar-style-2 {
		.nav {
			.nav-item {
				&.active {
					> a, > a:hover, > a:focus, > a[data-toggle=collapse][aria-expanded=true] {
						background: #fff !important;
						color: $color !important;
						p, i, .caret {
							color: $color !important;
						}
					}
				}
			}
		}
	}
	.scrollbar-inner > .scroll-element {
		.scroll-bar {
			background-color: #f7f7f7;
		}
		&.scroll-draggable .scroll-bar, &:hover .scroll-bar {
			background-color: #dcdbdb;
		}
	}
}

@mixin sidebar-color-style2($color) {
	background: $color !important;
	.user {
		margin-top: 0px;
		padding-top: 12.5px;
		border-top: 1px solid;
		border-color: rgba(255, 255, 255, 0.1) !important;
		.info { 
			a > span {
				color: #fff;
				.user-level {
					color: #eaeaea;
				}
			}
			.caret {
				border-top-color: #fff;
			}
		}
	}
	.nav {
		.nav-item {
			a {
				color: #eaeaea !important;
				p, i, .caret {
					color: #eaeaea !important;
				}
				&:hover, &:focus, &[data-toggle=collapse][aria-expanded=true] {
					color: #fff !important;
					p, i, .caret {
						color: #fff !important;
					}
				}
			}
		}
		> .nav-item {
			&.active {
				> a, > a:hover, > a:focus, > a[data-toggle=collapse][aria-expanded=true] {
					background: #fff !important;
					color: $color !important;
					p, i, .caret {
						color: $color !important;
					}
				}
			}
		}
		.nav-section {
			.text-section, .sidebar-mini-icon {
				color: #eaeaea;
			}
		}
		.nav-collapse li a .sub-item:before {
			background: #eaeaea;
		}
	}
	.scrollbar-inner > .scroll-element {
		.scroll-bar {
			background-color: #f7f7f7;
		}
		&.scroll-draggable .scroll-bar, &:hover .scroll-bar {
			background-color: #dcdbdb;
		}
	}
}

//[data-background-color="white"]
.logo-header[data-background-color="white"]{
	@include logo-header-color-style($white-color);
}
.navbar-header[data-background-color="white"] {
	@include navbar-header-color-style($white-color);
}

//[data-background-color="dark"]

.logo-header[data-background-color="dark"]{
	@include logo-header-color-style($dark-color);
}
.logo-header[data-background-color="dark2"]{
	@include logo-header-color-style($dark2-color);
}
.navbar-header[data-background-color="dark"] {
	@include navbar-header-color-style($dark-color);
}
.navbar-header[data-background-color="dark2"] {
	@include navbar-header-color-style($dark2-color);
}

//[data-background-color="blue"]

.logo-header[data-background-color="blue"]{
	@include logo-header-color-style($primary-color);
}
.logo-header[data-background-color="blue2"]{
	@include logo-header-color-style($primary2-color);
}
.navbar-header[data-background-color="blue"] {
	@include navbar-header-color-style($primary-color);
}
.navbar-header[data-background-color="blue2"] {
	@include navbar-header-color-style($primary2-color);
}

//[data-background-color="purple"]

.logo-header[data-background-color="purple"]{
	@include logo-header-color-style($secondary-color);
}
.logo-header[data-background-color="purple2"]{
	@include logo-header-color-style($secondary2-color);
}
.navbar-header[data-background-color="purple"] {
	@include navbar-header-color-style($secondary-color);
}
.navbar-header[data-background-color="purple2"] {
	@include navbar-header-color-style($secondary2-color);
}

//[data-background-color="light-blue"]

.logo-header[data-background-color="light-blue"]{
	@include logo-header-color-style($info-color);
}
.logo-header[data-background-color="light-blue2"]{
	@include logo-header-color-style($info2-color);
}
.navbar-header[data-background-color="light-blue"] {
	@include navbar-header-color-style($info-color);
}
.navbar-header[data-background-color="light-blue2"] {
	@include navbar-header-color-style($info2-color);
}

//[data-background-color="green"]

.logo-header[data-background-color="green"]{
	@include logo-header-color-style($green-color);
}
.logo-header[data-background-color="green2"]{
	@include logo-header-color-style($green2-color);
}
.navbar-header[data-background-color="green"] {
	@include navbar-header-color-style($green-color);
}
.navbar-header[data-background-color="green2"] {
	@include navbar-header-color-style($green2-color);
}

//[data-background-color="orange"]

.logo-header[data-background-color="orange"]{
	@include logo-header-color-style($warning-color);
}
.logo-header[data-background-color="orange2"]{
	@include logo-header-color-style($warning2-color);
}
.navbar-header[data-background-color="orange"] {
	@include navbar-header-color-style($warning-color);
}
.navbar-header[data-background-color="orange2"] {
	@include navbar-header-color-style($warning2-color);
}

//[data-background-color="red"]

.logo-header[data-background-color="red"]{
	@include logo-header-color-style($danger-color);
}
.logo-header[data-background-color="red2"]{
	@include logo-header-color-style($danger2-color);
}
.navbar-header[data-background-color="red"] {
	@include navbar-header-color-style($danger-color);
}
.navbar-header[data-background-color="red2"] {
	@include navbar-header-color-style($danger2-color);
}

.sidebar[data-background-color="dark"] {
	@include sidebar-color-style($dark-color);
}

.sidebar[data-background-color="dark2"] {
	@include sidebar-color-style($dark2-color);
}

.sidebar[data-background-color="blue"] {
	@include sidebar-color-style2($primary-color);
}

.sidebar[data-background-color="blue2"] {
	@include sidebar-color-style2($primary2-color);
}

.sidebar[data-background-color="purple"] {
	@include sidebar-color-style2($secondary-color);
}

.sidebar[data-background-color="purple2"] {
	@include sidebar-color-style2($secondary2-color);
}

.sidebar[data-background-color="light-blue"] {
	@include sidebar-color-style2($info-color);
}

.sidebar[data-background-color="light-blue2"] {
	@include sidebar-color-style2($info2-color);
}

.sidebar[data-background-color="green"] {
	@include sidebar-color-style2($green-color);
}

.sidebar[data-background-color="green2"] {
	@include sidebar-color-style2($green-color);
}

.sidebar[data-background-color="orange"] {
	@include sidebar-color-style2($warning-color);
}

.sidebar[data-background-color="orange2"] {
	@include sidebar-color-style2($warning2-color);
}

.sidebar[data-background-color="red"] {
	@include sidebar-color-style2($danger-color);
}

.sidebar[data-background-color="red2"] {
	@include sidebar-color-style2($danger2-color);
}

